.Projects {

  .project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 720px;
    opacity: 0.75;
    transition: opacity 0.3s ease;
    background: rgba(10, 10, 10, 1);
    margin: 0 0 20px;
  }
  
  .project-card:hover {
    opacity: 1;
  }
  
  .project-img {
    max-width: 90%;
    max-height: 400px;
    margin-bottom: 12px;
  }
  
  .project-description {
    width: 90%;
  }
  
  .project-link-ctnr {
    position: relative;
    margin-top: auto;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 20px 0;
  }

  .already-here {
    color: rgb(98, 43, 226);
    position: absolute;
    align-self: center;
    left: 16%;
    max-width: 33%;
    padding: 0 0 20px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
    &:hover {
      opacity: 1;
      & + .pf-link {
        opacity: 0;
      }
    }
  }
  .pf-link {
    opacity: 1;
    transition: all 0.3s ease;
  }
}