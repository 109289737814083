.Footer {

  .footer-link-ctnr {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 0 72px;
  }
  
  .footer-icon {
    color: white;
  }
}